@use './config/' as *;
@forward "./main-scss/";
%separator-right {
    content: "";
    width: 0.1rem;
    height: 100%;
    right: 0;
    opacity: .1;
    @extend %neutral-50-bg;
    @extend %pos-y-center;
}
%separator-bottom {
    content: "";
    height: 0.1rem;
    width: 100%;
    opacity: .1;
    @extend %neutral-50-bg;
    @include position(null, 0, 0, 0);
}
.waf-player {
    @extend %pb-4;
    .waf-head {
        @extend %half-radius;
        @extend %hidden;
        @extend %neutral-50-bg;
    }
    .stats-head {
        flex-wrap: wrap;
        @extend %flex;
        .title {
            @extend %flex-fs-c;
        }
        .tab-list {
            @extend %w-100;
        }
    }
    .waf-body {
        @extend %relative;
        &::before {
            content: '';
            width: var(--window-inner-width);
            z-index: var(--z-patterns);
            pointer-events: none;
            @include background(var(--neutral-50), "player-profile/statistics-mob-bg.png", center / cover no-repeat);
            @extend %h-100;
            @extend %pos-center;
        }
    }
    .player {
        &-name {
            @extend %uppercase;
            @extend %neutral-50;
            @extend %flex-column-n-c;
            @extend %mb-2;
            .first-name {
                @extend %font-24-pr;
            }
            .last-name {
                @extend %font-24-pb;
            }
        }
        &-thumbnail {
            @extend %relative;
            &::after {
                content: "";
                pointer-events: none;
                z-index: var(--z-overlay);
                inset: 0;
                background: linear-gradient(0deg, var(--secondary-1100) 5%, var(--secondary-1100) 0%, rgba(0, 0, 0, 0) 25%);
                bottom: -.2rem;
                @extend %absolute;
            }
        }
        &-info-wrap {
            z-index: 1;
            @extend %flex-column;
            @extend %relative;
            @extend %secondary-1100-bg;
            &::after {
                content: "";
                top: 0;
                height: 23rem;
                z-index: -1;
                @extend %absolute;
                @extend %w-100;
                @include background(clr(neutral-50), "player-profile/profile-bg-mob.jpg", top/cover no-repeat);
            }
        }
        &-info-list {
            @extend %mb-3;
        }
        &-role {
            gap: var(--space-2);
            @extend %flex-c-n;
            .role {
                @extend %font-10-pb;
                @extend %secondary-1000;
                @extend %secondary-300-bg;
                @extend %half-radius;
                @extend %p-1;
                @extend %capitalize;
            }
        }
        &-stats-head {
            @extend %py-3;
            @extend %font-18-pr;
            @extend %secondary-1000;
            @extend %uppercase;
        }
        &-stats-list {
            order: 1;
            @extend %neutral-50-bg;
            @extend %px-3;
        }
        &-stats-item {
            @include grid(2);
            @extend %py-3;
            @extend %relative;
            &:first-child {
                @extend %d-block;
                @extend %py-0;
            }
            &:last-child {
                border-bottom: .1rem solid clr(neutral-100, 2);
            }
            &:not(:last-child)::after {
                content: '';
                opacity: 0.3;
                height: 0.1rem;
                background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
                bottom: 0;
                @extend %w-100;
                @extend %pos-x-center;
            }
        }
        &-stats-title {
            @extend %font-12-pr;
            @extend %neutral-800;
            @extend %uppercase;
        }
        &-stats-count {
            @extend %font-18-pb;
            @extend %text-right;
            @extend %secondary-1000;
        }
        &-meta {
            &-title {
                @extend %neutral-50-5;
                @extend %font-13-pr;
                @extend %mb-1;
                @extend %capitalize;
            }
            &-value {
                @extend %font-14-pb;
                @extend %neutral-50;
            }
            &-item {
                @extend %relative;
                @extend %p-3;
                @extend %text-center;
                &::before {
                    @extend %separator-right;
                }
                &::after {
                    @extend %separator-bottom;
                }
                &:last-child {
                    &::before {
                        @extend %d-none;
                    }
                }
                &.debut {
                    @extend %d-none;
                }
            }
        }
        &-meta-list {
            border-top: .1rem solid clr(neutral-50, 1);
            border-bottom: .1rem solid clr(neutral-50, 1);
            flex-wrap: wrap;
            justify-content: center;
            @include card-count(2, var(--space-1));
            gap: unset;
        }
        &-thumbnail {
            width: 24rem;
            order: -1;
            @extend %mt-4;
            @extend %mx-auto;
        }
        &-bio-title {
            @extend %font-20-pb;
            @extend %secondary-1000;
            @extend %pt-3;
            @extend %pb-1;
        }
        &-bio-wrap {
            @extend %relative;
            @extend %pb-12;
            @extend %px-3;
            .toggle-bio {
                bottom: var(--space-5);
                @extend %absolute;
                .btn-text {
                    height: 2.2rem;
                    min-width: 6.8rem;
                    @extend %secondary-1000;
                    @extend %uppercase;
                    @extend %p-1-2;
                    @extend %primary-500-bg;
                    @extend %half-radius;
                    @extend %font-0;
                    @extend %d-inline-block;
                    &:before {
                        content: "READ MORE";
                        @extend %font-10-pb;
                    }
                }
            }
            .content {
                @include truncate(7, 14, 2);
                @extend %neutral-900;
            }
        }
        // PLAYER TRACKER CSS START
        &-stats-category-title {
            @extend %text-center;
            @extend %uppercase;
            @extend %secondary-1100;
            @extend %my-3;
            @extend %relative;
            .title {
                @extend %font-16-pb;
            }
            .title::after,
            .title::before {
                content: "";
                height: .1rem;
                width: 25%;
                @extend %secondary-1100-bg;
                @extend %d-block;
                @extend %pos-y-center;
            }
            .title::before {
                right: 0;
            }
        }
        &-stats-category-list {
            flex-wrap: wrap;
            justify-content: center;
            @include grid(2, 0);
            @extend %neutral-50-bg;
            @extend %full-radius;
            &:after {
                content: '';
                background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
                height: 100%;
                pointer-events: none;
                opacity: .3;
                width: 0.1rem;
                display: block;
                @include position-combo(x-center);
            }
            .player-stats-item {
                @extend %flex-column-c-c;
                &:nth-child(odd) {
                    &:before {
                        content: '';
                        background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
                        height: 0.1rem;
                        pointer-events: none;
                        opacity: .3;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 50%;
                    }
                }
            }
        }
        &-stats-category {
            @extend %relative;
        }
        &-stats-pitch {
            @extend %mt-3;
            .pitch-img {
                aspect-ratio: 236/111
            }
        }
    }
    .player-country,
    .filter-section {
        @extend %d-none;
    }
    .stats {
        &-head {
            @extend %mt-8;
            .title {
                @extend %uppercase;
                @extend %secondary-1100;
                @extend %font-18-pb;
                @extend %mb-4;
            }
            .tab-list {
                @include grid(2, 0);
                @extend %hidden;
                @extend %text-center;
                @extend %secondary-1000-bg;
                @extend %half-radius;
                @extend %px-0;
                @extend %mb-6;
            }
            .tab-item {
                @extend %font-11-pb;
                @extend %uppercase;
                @extend %neutral-50;
                @extend %py-2;
                &.active {
                    @extend %primary-500-bg;
                    @extend %secondary-1000;
                    button {
                        @extend %secondary-1000;
                    }
                }
                button {
                    @extend %neutral-50;
                }
            }
        }
        &-body {
            .stats-table {
                border-radius: var(--full-radius);
                .table {
                    &-responsive {
                        @extend %mb-5;
                    }
                    &-data {
                        width: 100%;
                        height: 4rem;
                    }
                    &-head {
                        .text {
                            @extend %primary-500;
                            @extend %font-14-pb;
                            @extend %uppercase;
                        }
                    }
                    &-left {
                        .table-body {
                            .text {
                                @extend %font-14-pr;
                                @extend %secondary-1000;
                            }
                        }
                    }
                    &-right {
                        .table-body {
                            .text {
                                @extend %font-14-pb;
                                @extend %secondary-1100;
                            }
                        }
                    }
                }
            }
        }
    }
    .player-country,
    .filter-section {
        @extend %d-none;
    }
    .dropdown-group {
        margin-inline: auto 0;
    }
    .dropdown-label {
        @extend %d-none;
    }
    .selected-title {
        .btn-text {
            @extend %font-12-pr;
        }
    }
    .waf-select-box {
        min-width: 10rem;
        @extend %mb-4;
        .selected-title {
            &[aria-expanded=true] {
                &::after {
                    transform: rotate(180deg);
                }
                & ~ .select-box-wrap {
                    @include dropdown(open);
                }
            }
            &::after {
                rotate: 0deg;
                top: 0.3rem;
                @extend %font-10;
            }
        }
        .select-box-wrap {
            min-width: 100%;
            height: 20rem;
            overflow-y: scroll;
            @include custom-scroll();
            @include dropdown(close);
            @extend %d-block;
        }
        .select-list {
            cursor: pointer;
            @extend %neutral-50-bg;
            .list-item {
                @extend %neutral-50-bg;
                @extend %font-12-pr;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-player {
        --_state-list: 17%;
        position: relative;
        &::before {
            content: '';
            width: var(--window-inner-width);
            z-index: var(--z-profile-pattern);
            height: 100%;
            @include background(var(--neutral-50), "player-profile/statistics-web-bg.png", center / cover no-repeat);
            @include position-combo(center);
        }
        .waf-head {
            position: relative;
        }
        .waf-body::before {
            display: none;
        }
        .waf-select-box {
            min-width: 12.5rem;
        }
        .player {
            &-info-wrap {
                display: grid;
                grid-template-columns: calc(100% - var(--_state-list)) var(--_state-list);
                background-color: clr(secondary-1100);
                position: static;
                &::after {
                    content: "";
                    height: 100%;
                    position: absolute;
                    right: var(--_state-list);
                    width: var(--_state-list);
                    z-index: 2;
                    @include background(clr(secondary-50), "player-profile/profile-pattern.jpg", top/cover no-repeat);
                }
                .role::after {
                    position: absolute;
                    left: var(--space-6);
                    top: var(--space-4);
                    color: clr(neutral-50);
                    background-color: clr(secondary-700);
                    border-radius: 50%;
                    font-size: 1.2rem;
                    @include square(3rem);
                    @include flex-config(flex, null, center, center);
                }
                &.captain .role::after {
                    content: "C";
                }
                &.overseas .role::after {
                    color: clr(neutral-1000);
                    background-color: clr(primary-500);
                    @include icon(flight);
                }
            }
            &-name {
                grid-area: 1 / 1 / 2 / 2;
                align-items: flex-start;
                padding: var(--space-12) 40% 0 var(--space-6);
                .name {
                    font-size: 3.5rem;
                }
            }
            &-role {
                justify-content: flex-start;
            }
            &-info-list {
                grid-area: 2 / 1 / 3 / 2;
                padding-inline: var(--space-6) 40%;
                margin-bottom: var(--space-5);
            }
            &-meta-title {
                margin-bottom: var(--space-2);
            }
            &-meta-list {
                grid-area: 3 / 1 / 4 / 2;
                padding-inline: var(--space-4) 40%;
                @include card-count(3, var(--space-1));
            }
            &-meta-item {
                text-align: left;
                padding-block: var(--space-5);
            }
            &-stats-list {
                grid-area: 1 / 2 / 7 / 3;
                @include background(clr(secondary-50), "player-profile/profile-bg-web.png", left center/cover no-repeat);
                &::after {
                    height: 100%;
                    width: 15%;
                    @include background(clr(secondary-50), "player-profile/profile-bg-web.png", top/cover no-repeat);
                    @include position-combo(tr);
                }
            }
            &-bio-wrap {
                width: 75%;
                z-index: var(--z-default);
                background-color: clr(neutral-50);
                padding-inline: var(--space-6) 25%;
                @include position-combo(bl);
                .content {
                    @include truncate(4, 14, 2);
                }
            }
            &-bio-title {
                padding-top: var(--space-5);
            }
            &-stats-head {
                font-weight: 700;
                text-align: center;
                padding-block: var(--space-7);
            }
            &-stats-item {
                @include flex-config(flex, column-reverse, null, center);
            }
            &-thumbnail {
                position: absolute;
                right: calc(var(--_state-list) + 1%);
                bottom: 0;
                overflow: hidden;
                z-index: 3;
                width: 33rem;
                margin-top: 0;
                &::after {
                    display: none;
                }
            }
            &-stats-count {
                font-size: 2.4rem;
            }
            &-tracker-view {
                position: relative;
                height: 100%;
                padding-bottom: var(--space-6);
                @include card-count(3, var(--space-1));
                overflow-x: unset;
            }
            &-stats-item {
                padding-block: var(--space-3);
            }
            &-stats-category {
                .title::after,
                .title::before {
                    display: none;
                }
                &:nth-child(1) .player-stats-category-title,
                &:nth-child(3) .player-stats-category-title {
                    white-space: nowrap;
                    position: absolute;
                }
                &:nth-child(1) .player-stats-category-title {
                    left: 100%;
                    margin: var(--space-6) 0 0 var(--space-4);
                }
                &:nth-child(3) .player-stats-category-title {
                    right: 100%;
                    bottom: 0;
                    margin: 0 var(--space-4) var(--space-6) 0;
                }
                &-title {
                    .title {
                        font-size: 2rem;
                    }
                }
            }
            &-stats-pitch {
                position: relative;
                margin-block: var(--space-4);
                &::after {
                    content: "";
                    position: absolute;
                    bottom: var(--space-1-neg);
                    right: 0;
                    border-radius: 50%;
                    background: clr(secondary-1000);
                    @include square(1rem);
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: var(--space-1-neg);
                    border-radius: 50%;
                    background: clr(secondary-1000);
                    @include square(1rem);
                }
            }
        }
        .stats {
            &-head {
                display: flex;
                justify-content: space-between;
                .dropdown-group {
                    margin-inline: auto var(--space-3);
                }
                .title {
                    font-size: 2.2rem;
                    align-items: flex-start;
                }
                .tab-list {
                    width: unset;
                    grid-template-columns: repeat(2, 15rem);
                }
            }
            &-body {
                .stats-table {
                    border-radius: var(--full-radius);
                    .table {
                        &-body {
                            .table-row:first-child .text {
                                color: clr(neutral-1000);
                                font: 700 1.4rem/1 $font-primary;
                            }
                            .table-row:last-child .table-data {
                                background-color: clr(secondary-1000, 1.5);
                                .text {
                                    font-weight: 500;
                                }
                            }
                        }
                        &-data {
                            width: 100%;
                            height: 5.5rem;
                        }
                        &-left {
                            width: 18rem;
                        }
                        &-right {
                            width: calc(100% - 18rem);
                            overflow-x: auto;
                            .table-data {
                                min-width: 10rem;
                                width: 100%;
                                // flex: 0 0 10rem;
                                flex-shrink: unset;
                            }
                            .table-body {
                                .text {
                                    font: 400 1.4rem/1 $font-primary;
                                }
                            }
                        }
                    }
                }
                .player-stats-item {
                    padding-block: var(--space-4);
                }
            }
        }
        .pitch-section {
            height: 100%;
            @include flex-config(flex, null, null, center);
            &::after,
            &::before {
                content: "";
                width: 50%;
                height: 100%;
                border: solid clr(secondary-1000);
                border-width: .1rem .1rem 0 0;
                position: absolute;
                top: 0;
                z-index: var(--z-patterns);
                display: block;
                pointer-events: none;
            }
            &::before {
                border-width: 0 0 .1rem;
                right: 0;
            }
        }
    }
}
@media screen and (max-width: 350px) {
    .waf-player {
        .player {
            &-info-wrap {
                &::after {
                    height: 17rem;
                }
            }
        }
    }
}
@media screen and (max-width: 375px) {
    .waf-player {
        .player {
            &-info-wrap {
                &::after {
                    height: 20rem;
                }
            }
        }
    }
}